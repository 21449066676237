<template>
    <div class="follow">
        <span>关注</span>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>